*{
  font-family: "Poppins",Helvetica,Arial,Lucida,sans-serif !important;
}
body{
  /*background-image: linear-gradient(169deg,#bf1516 0%,#141a28 64%) !important;*/
  /*background-color: #fff !important;*/
  font-family: "Poppins",Helvetica,Arial,Lucida,sans-serif !important;
  /*color: #fff !important;*/
  min-height: 100% !important;
}

.login-title{
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 40px !important;
}

html, body, .app, .app>div {
  min-height: 100% !important
}

.rem-button{
    color: #FFFFFF!important;
    border-width: 0px!important;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif!important;
    background-color: #7f0215;
}

.App {
  text-align: center;
  min-height: 100%!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 250px;
  width: 250px;
}

.qr {
  height: 150px;
  width: 150px;
}

.red_logo_2{
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.panel {
  margin: 40px;
}

.text-balance{
  text-align: right;
  font-size: 13px;
}

.text-balance-inverse{
  text-align: left;
  font-size: 13px;
}

.panel-enviar{
  width: 60%;
  text-align: center;
  align-items: center;
  align-content: center;
}

.label-cantidad {
  margin: 10px 30px;
}
.MuiBottomNavigationAction-root{
  transition: all ease 0.4s!important;
}
.MuiBottomNavigationAction-root.Mui-selected{
  color: #fff!important;
  background: #e72929!important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}
